<template>
    <div>
        <div>
            <div class="chart-legend">
                <div v-for="(label, index) in data.labels" :key="index" class="legend-item">
                    <span class="legend-color" :style="{ backgroundColor: data.datasets[0].backgroundColor[index] }"></span>
                    <span class="legend-label">{{ label }}</span>
                    <span class="legend-percentage">{{ (Math.round(data.datasets[0].data[index] * 100) /100).toFixed(2) ?? 0 }}%</span>
                </div>
            </div>
        </div>
        <div>

            <Doughnut
            id="my-chart-id"
            :options="chartOptions"
            :data="data"
            />
        </div>
    </div>
</template>

<script setup>
// AVOID USING PINIA, VUE ROUTER or LocalState This component is used by BrowserShot server side for report generation
import { defineProps } from 'vue'
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

const props = defineProps (['greenPercentage', 'amberPercentage', 'redPercentage', 'ungradedPercentage']);

ChartJS.register(ArcElement,  ChartDataLabels)


const data = {
    labels: ['Passed', 'Failed', 'Not\ninspected'],
    datasets: [
        {
            backgroundColor: ['rgba(76, 200, 0, 1)', 'rgba(220, 0, 0,1)', '#959595'],
            data: [props.greenPercentage, props.redPercentage, props.ungradedPercentage],
            datalabels: {
                labels: {
                    title: {
                        color: 'white'
                    }
                }
            }
        }
    ]
}

const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                formatter: function(value, context) {

                    if(context.chart.data.labels[context.dataIndex] && value )
                    {
                        return `${Math.floor(value)}%`;
                    }
                    else {
                        return ""
                    }
                }
            }
        }
      }

window.status = 'ready'
</script>

<style scoped>
.chart-legend {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.legend-label {
  flex: 1;
}

.legend-percentage {
  margin-left: auto;
}
</style>
